<template>
  <section
    class="!py-lg md:!py-2xl bg-white"
    data-test-id="branchDetailsMeetTeamSection"
    data-fs="branchDetailsMeetTeamSection"
  >
    <Heading tag="h2" align="center" size="md" class="mb-sm md:mb-2xl">Meet the Team</Heading>

    <LazySlider
      center-insufficient-slides
      :draggable="false"
      :autoplay="false"
      :breakpoints="breakpoints"
      class="wrapper-left !py-none"
    >
      <swiper-slide v-for="(member, index) in teamMembers(page)" :key="`members-${index}`" class="pb-2xs">
        <SimpleCard remove-padding rounded data-test-id="branchSimpleCard" data-fs="branchSimpleCard">
          <NuxtPicture
            loading="lazy"
            quality="100"
            :src="member.image"
            :alt="`${member.string1} headshot image`"
            :img-attrs="{
              class: 'rounded-tl-sm rounded-tr-sm h-[300px] lg:h-[350px] xl:h-[400px] w-full object-cover object-top',
            }"
          />
          <div class="p-xs sm:p-sm h-[100px] sm:h-[130px] md:h-[120px]">
            <Text
              weight="bold"
              align="center"
              data-fs="branchDetailsTeamMemberName"
              data-test-id="branchDetailsTeamMemberName"
              >{{ member.name }}</Text
            >
            <Text align="center" data-test-id="branchDetailsTeamMemberRole" data-fs="branchDetailsTeamMemberRole">
              {{ member.string1 }}
            </Text>
          </div>
        </SimpleCard>
      </swiper-slide>
    </LazySlider>
  </section>
</template>

<script setup lang="ts">
import { SwiperSlide } from "swiper/vue";
import { BranchMembersProps, PageData } from "./branchDetailsTypes";
const breakpoints = {
  320: {
    slidesPerView: 1.4,
    spaceBetween: 48,
  },
  420: {
    slidesPerView: 1.8,
    spaceBetween: 48,
  },
  520: {
    slidesPerView: 2.4,
    spaceBetween: 48,
  },
  768: {
    slidesPerView: 3.4,
    spaceBetween: 24,
  },
  960: {
    slidesPerView: 4.4,
    spaceBetween: 24,
  },
  1000: {
    slidesPerView: 6.2,
    spaceBetween: 24,
  },
};
const props = defineProps<BranchMembersProps>();

const { page } = toRefs(props);

const content = (page: PageData) => {
  return page.getDocument()?.getData();
};

const teamMembers = (page: PageData) => {
  return (content(page)?.team || [])
    .filter((teamMember: any) => teamMember.image != null && teamMember.string.length > 0)
    .map((teamMember: any) => ({
      ...teamMember,
      name: teamMember.string,
      role: teamMember.string1,
      image: teamMember.image?.$ref && page.getContent(teamMember.image?.$ref)?.original?.model?.links?.site?.href,
    }));
};
</script>
